import React from "react";
import logoImage from "../../assets/id-safe-logo.png";
import styles from "./Header.module.css";

const Header = () => {
  const headerLinks = [
    {
      slug: "home",
      name: "Home",
      link: "https://idsafeus.com/",
    },
    {
      slug: "contact",
      name: "Contact Us",
      link: "https://idsafeus.com/contact",
    },
  ];

  return (
    <div className="px-[120px]">
      <nav class="border-gray-200 white:bg-gray-900 my-[20px]">
        <div class="flex flex-wrap justify-between items-center mx-auto">
          <div>
            <img
              className="cursor-pointer"
              src={logoImage}
              alt="logo"
              onClick={() => {
                window.location.href = "https://idsafeus.com/";
              }}
            />
          </div>
          <div className={"flex items-center"}>
            {headerLinks?.map((link) => (
              <p
                key={link.slug}
                className={`${styles.link}`}
                onClick={() => {
                  window.location.href = link.link;
                }}
              >
                {link?.name}
              </p>
            ))}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
